import Vue from 'vue'
import DynamicForm from '../components/DynamicForm'
import LoginWrapper from '../components/LoginWrap'
import DynamicHtml from '../components/DynamicHtml'

Vue.component('dynamic-form', DynamicForm)
Vue.component('login-wrapper', LoginWrapper)
Vue.component('dynamic-html', DynamicHtml)

let loaded = {}
let apiResponseCache = {}
const apiHost = process.env.VUE_APP_API_HOST;

const loadComponent = async (comp, comps) => {
  if (!comp) { return }
  (comp.deps || []).forEach(depId => {
    if (loaded[depId]) { return }
    const depComp = comps.find(c => c._id === depId)
    loadComponent(depComp, comps)
  })

  const { name, template, data, props } = comp
  const compDef = { template }
  if (props) { compDef.props = props }

  if (data) {
    const initialData = {
      global: window
    }
    data.forEach(item => {
      const dataKey = item.key
      initialData[dataKey] = item.value || item.default_value
    })
    compDef.data = () => ({ ...initialData } )
    const watchs = {}

    const ggLoadDataFunc = async function (self) {
      self.$root.$emit('setLoading', true)
      var search = location.search.substring(1);
      const querySearchObject = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}
      await Promise.all(data.map(async item => {
        const dataKey = item.key
        let source_url = ''
        let res = null
        let v = null
        let headers = {}
        switch (item.type) {
          case 'remote':
            // resolve url params.
            source_url = item.resolve.source_url
            item.resolve.url_params.forEach(param => {
              v = null
              switch (param.source) {
                case 'query':
                  v = querySearchObject[param.from_name || param.name]
                  console.log('param.from_name || param.name: ', param.from_name || param.name);
                  break
                case 'prop':
                case 'data':
                  v = self[param.from_name || param.name]
                  watchs[param.from_name || param.name] = function () {
                    this.ggRefreshData()
                  }
                  break
                default:
                  break
              }
              source_url = source_url.replace(new RegExp(`:${param.name}`, 'g'), v)
            })
            // Check if item.resolve.headers has a length greater than 0
            if (item.resolve?.headers?.length) {
              headers = this.item.resolve.headers.reduce((acc, header) => {
                acc[header.name] = header.default_value;
                return acc;
              }, {});
            }
            if (apiResponseCache[source_url]) {
              res = apiResponseCache[source_url]
            } else {
              try {
                console.log("item=>>>>>>>", item)
                if (item.use_relay) {
                  res = await fetch(`${apiHost}/relay`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      url: source_url,
                      method: item.method ?? 'GET',
                      headers,
                      body: ''
                    })
                  })
                } else {
                  const token = window.localStorage.getItem('site-token')
                  res = await fetch(source_url, {
                    method: item.method ?? 'GET',
                    headers: {
                      ...headers,
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                    }
                  })
                }
                if (res.status === 401) {
                  // Unauthorized
                  window.localStorage.removeItem('site-token')
                  if (window.localStorage.getItem('login_required') === 'true') {
                    window.location.reload();
                  }
                  return
                }
                res = await res.json()
              } catch (err) {
                return
              }
            }
            item.resolve.section.split('.').slice(1).forEach(s => res = res[s])

            self[dataKey] = res
            // if (dataKey === 'ajaxRefreshRates') {
            //   self.$emit('set-refresh', { ajaxRefresh: res })
            // }
            // if (dataKey === 'fullRefreshRates') {
            //   self.$emit('set-refresh', { fullRefresh: res })
            // }
            break
          default:
            break
        }
      }))
      self.$root.$emit('setLoading', false)
    }

    const customComponentMethods = window[name] && window[name].methods || {}
    const customComponentMounted = window[name] && window[name].mounted
    const customComponentComputed = window[name] && window[name].computed || {}
    const customComponentWatchs = window[name] && window[name].watch || {}

    const mountedFunc = function () {
      this.ggRefreshData()
      try {
        customComponentMounted && customComponentMounted(this)
      } catch (err) {
        console.log('customComponentMounted', err)
      }
      this.$root.$on('ajax-reload', () => {
        this.ggRefreshData()
      })
    }

    compDef.mounted = mountedFunc
    compDef.methods = {
      ggLoadDataFunc,
      ggRefreshData () {
        ggLoadDataFunc(this)
      },
      ggAppRefresh () {
        return this.$root.$emit('ajax-reload')
      },
      ggQueryParam(name) {
        const sp = new URLSearchParams(window.location.search)
        return sp.get(name)
      },
      ...customComponentMethods
    }
    compDef.computed = {
      ...customComponentComputed
    }
    compDef.watch = {
      ...watchs,
      ...customComponentWatchs
    }
  }
  Vue.component(name, compDef)
}

export default function initComponents(initComps) {
  const comps = initComps.map(c => ({ ...c, name: `${c.name}` }))
  const appComponent = comps.find(c => c.name === 'app')
  console.log('appComponent: ', appComponent);

  loadComponent(appComponent, comps)

  // load Login component if exists
  const loginComponent = initComps.find(c => c.name === 'login')
  loadComponent(loginComponent, [])
}
